import React from "react";
import { Badge, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "../../component/SkillsSearch/Header/Header";

import RPLN_Logo from "../../asset/rplnPage.png";
import Skills_Logo from "../../asset/skillSearch.png";

import { CARD_DESCRIPTION } from "../../const";

import "./HomePage.css";
import LandingDisplayCard from "../../component/LandingDisplayCard/LandingDisplayCard";

export default function HomePage() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <Container maxWidth="md" margin="normal" className="homepage-container">
        <Typography variant="body3" style={{ textAlign: "center" }} mb={2}>
          Choose the search type
        </Typography>
        <Grid container columns={8}>
          <Grid item xs={4} md={4} style={{ marginLeft: "auto" }}>
            <Badge
              badgeContent="Current"
              className="grow-badge"
              color="primary"
              style={{
                marginTop: "10px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div onClick={() => navigate("/RPLN")}>
                <LandingDisplayCard
                  image={RPLN_Logo}
                  title={CARD_DESCRIPTION[0].title}
                  description={CARD_DESCRIPTION[0].description}
                  cardFor={CARD_DESCRIPTION[0].cardFor}
                  addMsg={CARD_DESCRIPTION[0].addMsg}
                />
              </div>
            </Badge>
          </Grid>
          <Grid item xs={4} md={4}>
            <Badge
              badgeContent="Newly Introduced"
              className="grow-badge"
              color="warning"
              style={{
                marginTop: "10px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div onClick={() => navigate("/Skills")}>
                <LandingDisplayCard
                  image={Skills_Logo}
                  title={CARD_DESCRIPTION[1].title}
                  description={CARD_DESCRIPTION[1].description}
                  cardFor={CARD_DESCRIPTION[1].cardFor}
                  addMsg={CARD_DESCRIPTION[1].addMsg}
                />
              </div>
            </Badge>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
