const groups = {
  Any: [
    "Any",
    "IDE-PPM-PEX-RTE",
    "IDE-DE-BA-PT-DigitalTechnology",
    "GFIDT-BP",
    "Ent IDT-Architecture & Data-DS Global Lubricants",
    "IDE-DE-BA-UP-Production RES",
    "IDE-DE-BA-DS-Mobility-Network",
    "IDE-DE-BA-DS-Mobility",
    "IDE-DE-TesterRetail-Fleets",
    "IDE-PPM-DS-SD CP GL B2B PF",
    "IDE-DE-S&C-DS-BCR",
    "IDE-PPM-PEX-Core Team",
    "Ent IDT-Architecture & Data-DS Low Carbon Solutions",
    "Ent IDT-Architecture & Data-SEAT",
    "IDE-T&S-Shell Energy-BA & E Design",
    "IDE-T&S-Crude & Products",
    "DS-DPF-ERP",
    "DS-SDL-Sectors & Decarbonisation",
    "IDE-SE-Anaplan, P1 and Resource One",
    "IDE-T&S-PPM",
    "IDE-T&S-Logistics & Cross Comm",
    "IDE-DE-FETesting",
    "IDE-SE-Software Engineering Sustainability",
    "IDE-DE-BA-DS-Mobility-GCC-Digital Integration",
    "IDE-DE-TesterRetail-FPS",
    "IDE-PPM-SEAT",
    "IDE-SE-Native SE & Cloud/DevOps",
    "IDE-DE-S&C-IGU IDT",
    "IDE-Information, Data & Analytics",
    "Architecture & Data - DS Customer & Renewables",
    "DS-DPF-Functions",
    "IDSO-Downstream-OR",
    "IDE-DE-BA-DS-Mobility-Retailing & Operations",
    "IDE-ERP-Customer",
    "IDE-Software Engineering",
    "IDE-SE-Agile Hub",
    "IDE-Strategy & Planning",
    "IDE-DE-IE-GF",
    "DS-SDL-Strat Accounts & Ventures",
    "DS-Mobility IDT BI Americas",
    "Architecture & Data - Global Functions",
    "IDE-ERP-Finance & Tax",
    "IDE-ERP-PPM Delivery",
    "IDE-DE-BA-GF-Finance IRM",
    "IDE-DE-BA-UP-Exploration",
    "IDE-DE-S4_QA",
    "DS-C&P-Products-Hub",
    "GFIDT",
    "IDSO-OIC-OR",
    "IDE-PPM-LAP",
    "IDE-DE-BA-DS-Mobility-e-Mobility",
    "IDE-SE-Software Engineering Sustainability ALL",
    "IDE-Program & Project Management",
    "IDE-DE-BA-GF-Legal & IT4IT",
    "IDE-DE-HR_QA",
    "IDE-SE-IBM Tririga Platform",
    "IDE-SE-Asset Management Systems",
    "IDE-SE-Agile Hub-Hydrogen demand",
    "DS-Mobility",
    "IDE-BPPC-Functional Excellence",
    "IDE-DE-TesterRetail-Loyalty_Mpay",
    "IDSO-ET&P DS Delivery",
    "IDE-SE-ServiceNow",
    "IDE-DE-BA-DS-SDLCP-Chemicals",
    "IDE-T&S-Shell Energy-IDA",
    "IDSO",
    "Ent IDT-Architecture & Data-DS T&S Shell Energy",
    "IDSO-ET&P-Others",
    "IDE-SE-RETS",
    "IDE-SE-Proactive Monitoring",
    "Ent IDT-Architecture & Data-UPIGPT Integrated Gas",
    "DS-Global Lubricants",
    "DS-SDL-Lubricants",
    "DS-SDL-Sectors & Decarbonisation Operations",
    "IDE-BPPC-Fleet Solutions",
    "IDE-IDA-Data Science & ML",
    "IDSO-ET&P GF Delivery",
    "Ent IDT-Architecture & Data-GF Corporate",
    "DS-Mobility IDT BI Fleet Solutions & CRT",
    "IDE-ERP-Asset",
    "IDE-T&S-Shell Energy- Endur-SLMT",
    "IDSO-ET&P",
    "IDE-SE-Software Engineering Excellence",
    "IDE-Power Digital Core",
    "IDE-IDA-Info Mgmt & Data Analytics",
    "IDE-SE-NSE-Experience Design Operations",
    "IDE-SE-NSE-eMobility",
    "IDE-DE-BA-DS-SDLCP-S&D",
    "IDE-T&S-Shell Energy- Endur-Aus",
    "IDE-T&S-Shell Energy- Software Engineering",
    "IDE-Investment & Planning",
    "IDE-BPPC-R&ES-Energy Marketing Design",
    "DS-Trading & Supply-BP",
    "IGU-PT IDT - BP",
    "IDE-PPM-Program & Project Mgmt.-UK",
    "IDE-DE-S&C-Global Functions/Enterprise IDT",
    "IDE-DE-ERP_QA",
    "IDE-T&S-Shell Energy-SE &A",
    "Ent IDT-Architecture & Data-GF Finance",
    "Ent IDT-Architecture & Data - Renewables",
    "Ent IDT-Architecture & Data-CIO Management",
    "IDE-T&S-Crude & Products-SE-Endur",
    "IDE-T&S-PPM-Shell Energy",
    "IDE-SE-Agile Hub-India\u00c2\u00a0Demand",
    "Ent IDT-Architecture & Data-EA Functional Excellence",
    "Ent IDT-Architecture & Data-DS DnA & Customer Ops",
    "IDE-SE-Adobe",
    "IDE-SE-Advanced Process Controls",
    "IDE-DE-PTIT_QA",
    "IDE-T&S-Crude & Products-SE",
    "IDE-T&S-PPM-Cross Commodity",
    "IDE-DE-Analytics_QA",
    "IDE-SE-SAP Commerce",
    "IDE-SE-Realtime-PHEA",
    "IGU-PT IDT",
    "DS-DPF",
    "IDE-T&S-PPM-PMO",
    "IDE-SE-Agile Hub-NL Demand",
    "Ent IDT-Architecture & Data-EA Cross Business",
    "Ent IDT-Architecture & Data-EA Innovation",
    "IDE-DE-BA-DS-A&D",
    "IDE-DE-S&C- PT IDT",
    "LCSDESSAV",
    "IDSO-PTSOM",
    "IDE-SE-Salesforce",
    "IDE-DE-DSIT-GCC_QA",
    "DS-Emerging Energy Solutions",
    "Ent IDT-Architecture & Data-IDSO SWE & Workspace",
    "DS-Mobility IDT BI East",
    "DS-Chemicals-Hub",
    "Enterprise IDT",
    "IDE-DE-S&C-Functional Excellence",
    "Architecture & Data - CIO",
    "IDE-SE-Agile Hub-UK Demand",
    "Ent IDT-Architecture & Data-DS Shared",
    "IDE-PPM-PEX-Agile Delivery Lead",
    "IDE-DE-FE-BusinessAnalyst",
    "IDE-ERP-Technical",
    "IGU-PT IDT - Hub",
    "DS-Low Carbon Solutions",
    "IDE-DE-BA-DS-Mobility-CC Lead",
    "Ent IDT-Architecture & Data-EA Security & IRM",
    "DS IDT-China IDT-China Architecture & Data",
    "MJL-Xander",
    "Ent IDT-Architecture & Data-UPIGPT C&P-Functions",
    "IDE-DE-BA-DS-Mobility-Fleet Solutions",
    "DS-C&P-Products-BP",
    "IDE-DE-BA-GF-Corporate",
    "Information and Digital Engineering",
    "IDE-PPM-Capital Projects & AD",
    "Downstream & Renewables IDT",
    "IDE-DE-BA-DS-Mobility-EUSA",
    "IDE-DE-TesterRetail-Settlement",
    "IDE-DE-RetailTestManagement",
    "Architecture & Data - IDSO",
    "SEIF Fundamental - User Access Management",
    "IDE-PPM-PEX",
    "IDE-SE-NSE-Fleet Solutions",
    "IDE-BPPC-R&ES-Energy Marketing Engineering",
    "DS-Mobility IDT BI Connected Customer ",
    "IDE-T&S-Shell Energy-Endur-EA",
    "IDE-T&S-Shell Energy-BA & E Design-SLMT",
    "IDSO-Others",
    "DS-SDL-Lubricants Fast Lubes",
    "IDE-ERP-S4-Technical",
    "IDSO-S&T",
    "IDE-DE-BA-PT-SEAT",
    "DS-SDL-Customer Operations",
    "IDE-BPPC",
    "IDE-SSW",
    "IDE-Enterprise Resource Management",
    "IDSO-ET&P Telecom Design",
    "DS-Chemicals & Products",
    "IDE-T&S-Shell Energy-BA & E Design-Aligne",
    "DS-SDL-Commerce & Marketplace",
    "IDE-IDA-DS-BF&DPF",
    "IDE-T&S-Shell Energy-BA & E Design-Asia & Aus",
    "IDE-DE-BA-DS-Mobility-Americas",
    "IDE-PPM-PMO",
    "IDE-IDA-Operational Excellence",
    "Ent IDT-Architecture & Data-DS Shell Energy-EM",
    "Ent IDT-Architecture & Data-GF IT4IT",
    "Ent IDT-Architecture & Data-GF Legal",
    "IDE-SE-NSE-S&D, Lubes & Cust Ops",
    "IDE-T&S-Powerhouse Geneva-Design",
    "IDE-T&S-Cross Comm-IDA",
    "SE Asia Data and Analytics PDT",
    "IDSO-ETSOM-MYIT-OSS",
    "IDE-PPM-Program & Project Mgmt.-IN-1",
    "IDE-SE-OsiSoft PI",
    "IDE-T&S-Cross Comm-BA",
    "IDSO-UPSOM-PM",
    "DS-Trading & Supply-Hub",
    "IDE-DE-S&C",
    "IDE-DE-S&C-DS IDT-RES",
    "IDE-ERP-S4 Center of Expertise",
    "IDE-DE-PTIT-Others",
    "IDE-DE-BA-DS-Mobility-SIP",
    "IDE-T&S-Logistics-SE",
    "Ent IDT-Architecture & Data-DS T&S-Horiz ShpngMrtm",
    "IDE-Design Engineering",
    "IDE-SE-Capital Projects",
    "IDE-T&S-Crude & Products-Testing\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0",
    "IDE-ERP Data",
    "IDE-ERP-Functional Excellence",
    "IDSO-ET&P UPIGPT Delivery",
    "IDE-T&S-Powerhouse Geneva-Hub",
    "Ent IDT-Architecture & Data-DS B2B CRM & Ecommerce",
    "IDSO-ET&P Technical Consulting Non ERP",
    "IDE-SE-ServiceNow, Workday, Automation",
    "IDE-PPM-RES IG",
    "Global Functions IDT",
    "Shell IDT Resource Group",
    "Architecture & Data - Assets and S&E",
    "Architecture & Data - DS Trading & Supply",
    "IDE-T&S-Powerhouse Geneva",
    "IDE-DE-TesterRetail-BI&DA",
    "IDE-DE-CBDT_QA",
    "IDE-PPM-PEX-Agile Coach",
    "Architecture & Data - DS Renewables",
    "IDE-T&S-S&C",
    "IDE-PPM-GF",
    "IDE-PPM-DS-MOBILITY",
    "Architecture & Data - DS Chems&Prods",
    "IDE-DE-Salesforce_QA",
    "IDSO-Global Functions-OR",
    "Ent IDT-Architecture & Data-IDSO Shared",
    "IDE-DE-BA-DS-Mobility-GCC-Loyalty / Media & Campaign",
    "DS IDT-China IDT-China Delivery Hub",
    "IDSO-ET&P Enterprise Delivery",
    "Ent IDT-Architecture & Data-UPIGPT COG-DeepWater",
    "IDE-DE-BA-GF-Finance",
    "Enterprise IDT-Architecture & Data",
    "Shell Energy IDT - Hub",
    "IDE-SE-Asset Mgmt & Realtime",
    "IDE-SE-Automation",
    "IDE-DE-IDSO_QA",
    "Architecture & Data - Strategy & Planning",
    "IDSO-SOM-PCD",
    "IDE-Trading & Supply",
    "IDE-SE-PEGA SSW",
    "IDE-ERP-S4 Delivery",
    "IDE-SE- Engineering and Maintenance",
    "IDE-DE-TesterRetail-NFR",
    "Ent IDT-Architecture & Data-UPIGPT Projects & Engineering",
    "DS-Mobility IDT BI EUSA",
    "IDE-DE-Testing",
    "IDE-SE-Nearshoring Demand",
    "Ent IDT-Architecture & Data-DS T&S Back Office&Logistics",
    "IDE-SE-Integration",
    "IDE-DE-BA-DS-Mobility-East",
    "DS-SDL-Data & Analytics",
    "IDE-IDA-Platform & Technology",
    "IDE-DE-BA-DS-SDLCP-Products",
    "IDE-DE-TesterRetail-SiteSystems",
    "Ent IDT-Architecture & Data-DS Mobility",
    "IDE-T&S-Shell Energy- Endur-Asia",
    "Ent IDT-Architecture & Data-DS T&S Crude&Products",
    "IDSO-SOM",
    "GLBDSSEADSDXSUP",
    "IDE-DE-BA-DS-SDLCP",
    "GLBGFXLFNTRSUP{SPTTEMP}",
    "Ent IDT-Architecture & Data-UPIGPT Shared",
    "IDE-T&S-Shell Energy-Testing",
    "DS-DPF-PD",
    "IDE-DE-UPNE_QA",
    "IDE-IDA-DS-B2B, B2C & Shell Energy",
    "IDE-SE-NSE-Tech & Ops Excellence",
    "DS-Mobility IDT BI Marketing & Network",
    "Ent IDT-Architecture & Data-IDSO Identity & Access Management",
    "IDE-T&S-Crude & Products-IDA",
    "IDE-SE-NSE-R&ES, PTIT, Other LOB's",
    "IDE-T&S-Shell Energy- Endur",
    "Ent IDT-Architecture & Data-GF Shared & SOM",
    "IDE-T&S-Crude & Products-BA-Endur",
    "IDE-T&S-PPM-Logistics",
    "Ent IDT-Architecture & Data-DS Global Lubes",
    "IDE-DE-S&C-IDSO-SOM",
    "IDE-SE-Realtime-IPSM",
    "IDSO-ET&P Technical Consulting ERP",
    "DS-DPF-A&R",
    "IDE-T&S-Crude & Products-BA",
    "IDE-T&S-PPM-Crude & products",
    "IDE-SE-NSE-Cloud DevOps",
    "IDE-DE-S&C-DS IDT-NON-Mobility IT",
    "IDE-DE-BA-PT-SurfaceTechnology",
    "Ent IDT-Architecture & Data-IDSO Portfolio Integration",
    "IDE-T&S-PPM-APM",
    "Shell Energy IDT",
    "Ent IDT-Architecture & Data-EA ERP SAP & DPF",
    "IDE-PPM-Program & Project Mgmt.-US",
    "IDE-SE-Asset Mgmt & Realtime-LIMS",
    "IDE-SE-Native SE & Experience Design",
    "IDE-DE-BA-DS-Mobility-Mobility Products",
    "IDE-DE-S&C-IRM IT",
    "IDE-PPM-PEX-Scrum Master",
    "IDE-T&S-Operational Excellence",
    "IDE-DE-BA-DS-Mobility-Data",
    "IDE-PPM-Program & Project Mgmt.-IN-2",
    "IDE-SE-Workday",
    "IDSO-Enterprise Technology-OR",
    "IDE-T&S-Cross Comm-SE",
    "GFIDT-Hub",
    "IDE-DE-TesterRetail-NFT",
    "IDE-DE-GFIDT_QA",
    "GLBDSSEADSGLSSUP",
    "IDE-PPM-Program & Project Mgmt.-NL",
    "Architecture & Data - Enterprise Architecture",
    "Shell Energy IDT - BP",
    "IDE-PPM-PT UP",
    "IDE-DE-Nonfunctional_QA",
    "IDE-BPPC-R&ES",
    "IDE-DE-BA-GF-HR",
    "Architecture & Data - UPIGPT",
    "Support",
    "IDE-DE-BA-DS-Mobility-B2C",
    "IDE-Transformational Change",
    "Ent IDT-Architecture & Data-UPIGPT SSW",
    "Ent IDT-Architecture & Data-IDSO Connectivity",
    "Ent IDT-Architecture & Data-IDSO Digital Platforms",
    "IDE-T&S-Shell Energy-BA & E Design-EA Power",
    "IDE-DIY",
    "IDE-IDA-Chem & Products",
    "IDE-BPPC-R&ES-Energy Marketing Product",
    "IDE-DE-BA-DS-SDLCP-Lubes",
    "Ent IDT-Architecture & Data-S&P Enterprise Projects",
    "Ent IDT-Architecture & Data-IDSO End User Productivity",
    "IDE-T&S-Shell Energy-Endur-US",
    "IDE-T&S-Shell Energy-BA & E Design-Horizontal",
    "DS-SDL-Lubricants Products & Services",
    "IDE-IDA-P&T, IG & Upstream",
    "IDE-SE-NSE-Products & Pricing",
    "Ent IDT-Architecture & Data-DS Chemicals & Products",
    "IDE-T&S-Shell Energy-BA & E Design-Americas",
    "IDE-DE-BA-DS-Mobility-Settlements",
    "DS IDT - China",
    "IDSO-ET&P Business Delivery",
    "IDE-SE-Business Platform",
    "IDE-SE-NSE-Mobility Others",
    "IDE-DE-BA-IPPD",
    "Ent IDT-Architecture & Data-IDSO ECP",
    "IDE-T&S-Shell Energy- Endur-Dev",
    "IDE-T&S-Shell Energy-BA & E Design-EPTB",
    "IDE-IDA-Data Mgt & Governance",
    "DS-IT Product Platform",
    "Ent IDT-Architecture & Data-IDSO Integration & Ops",
    "IDE-T&S-Logistics IDA",
    "DS-Trading & Supply",
    "IDE-DE-BA-DS-Mobility-Payments",
    "IDE-T&S-Shell Energy-BA & E Design-EA Gas",
    "IDE-SE-Technical Data",
    "IDE-T&S-Logistics-BA",
    "IDE-SE-Agile Hub-US Demand",
    "DS-Chemicals-BP",
    "IDE-DE-BA-DS-Mobility-GCC-Digital Sales & Portfolio",
    "IDE-SE-NSE-Computational Software Engineering",
    "EPT - Analytics - User Access Management",
    "IDE-ERP",
    "IDE-DE-S&C-IDSO-ET&P",
    "Ent IDT-Architecture & Data-GF HR",
    "IDE-SE-NSE-SSW",
    "IDE-DE-S&C-DS IDT-Mobility IT",
  ],
  IDE: [
    "IDE-PPM-PEX-RTE",
    "IDE-DE-BA-PT-DigitalTechnology",
    "IDE-DE-BA-UP-Production RES",
    "IDE-DE-BA-DS-Mobility-Network",
    "IDE-DE-BA-DS-Mobility",
    "IDE-DE-TesterRetail-Fleets",
    "IDE-PPM-DS-SD CP GL B2B PF",
    "IDE-DE-S&C-DS-BCR",
    "IDE-PPM-PEX-Core Team",
    "IDE-T&S-Shell Energy-BA & E Design",
    "IDE-T&S-Crude & Products",
    "IDE-SE-Anaplan, P1 and Resource One",
    "IDE-T&S-PPM",
    "IDE-T&S-Logistics & Cross Comm",
    "IDE-DE-FETesting",
    "IDE-SE-Software Engineering Sustainability",
    "IDE-DE-BA-DS-Mobility-GCC-Digital Integration",
    "IDE-DE-TesterRetail-FPS",
    "IDE-PPM-SEAT",
    "IDE-SE-Native SE & Cloud/DevOps",
    "IDE-DE-S&C-IGU IDT",
    "IDE-Information, Data & Analytics",
    "IDE-DE-BA-DS-Mobility-Retailing & Operations",
    "IDE-ERP-Customer",
    "IDE-Software Engineering",
    "IDE-SE-Agile Hub",
    "IDE-Strategy & Planning",
    "IDE-DE-IE-GF",
    "IDE-ERP-Finance & Tax",
    "IDE-ERP-PPM Delivery",
    "IDE-DE-BA-GF-Finance IRM",
    "IDE-DE-BA-UP-Exploration",
    "IDE-DE-S4_QA",
    "IDE-PPM-LAP",
    "IDE-DE-BA-DS-Mobility-e-Mobility",
    "IDE-SE-Software Engineering Sustainability ALL",
    "IDE-Program & Project Management",
    "IDE-DE-BA-GF-Legal & IT4IT",
    "IDE-DE-HR_QA",
    "IDE-SE-IBM Tririga Platform",
    "IDE-SE-Asset Management Systems",
    "IDE-SE-Agile Hub-Hydrogen demand",
    "IDE-BPPC-Functional Excellence",
    "IDE-DE-TesterRetail-Loyalty_Mpay",
    "IDE-SE-ServiceNow",
    "IDE-DE-BA-DS-SDLCP-Chemicals",
    "IDE-T&S-Shell Energy-IDA",
    "IDE-SE-RETS",
    "IDE-SE-Proactive Monitoring",
    "IDE-BPPC-Fleet Solutions",
    "IDE-IDA-Data Science & ML",
    "IDE-ERP-Asset",
    "IDE-T&S-Shell Energy- Endur-SLMT",
    "IDE-SE-Software Engineering Excellence",
    "IDE-Power Digital Core",
    "IDE-IDA-Info Mgmt & Data Analytics",
    "IDE-SE-NSE-Experience Design Operations",
    "IDE-SE-NSE-eMobility",
    "IDE-DE-BA-DS-SDLCP-S&D",
    "IDE-T&S-Shell Energy- Endur-Aus",
    "IDE-T&S-Shell Energy- Software Engineering",
    "IDE-Investment & Planning",
    "IDE-BPPC-R&ES-Energy Marketing Design",
    "IDE-PPM-Program & Project Mgmt.-UK",
    "IDE-DE-S&C-Global Functions/Enterprise IDT",
    "IDE-DE-ERP_QA",
    "IDE-T&S-Shell Energy-SE &A",
    "IDE-T&S-Crude & Products-SE-Endur",
    "IDE-T&S-PPM-Shell Energy",
    "IDE-SE-Agile Hub-India\u00c2\u00a0Demand",
    "IDE-SE-Adobe",
    "IDE-SE-Advanced Process Controls",
    "IDE-DE-PTIT_QA",
    "IDE-T&S-Crude & Products-SE",
    "IDE-T&S-PPM-Cross Commodity",
    "IDE-DE-Analytics_QA",
    "IDE-SE-SAP Commerce",
    "IDE-SE-Realtime-PHEA",
    "IDE-T&S-PPM-PMO",
    "IDE-SE-Agile Hub-NL Demand",
    "IDE-DE-BA-DS-A&D",
    "IDE-DE-S&C- PT IDT",
    "IDE-SE-Salesforce",
    "IDE-DE-DSIT-GCC_QA",
    "IDE-DE-S&C-Functional Excellence",
    "IDE-SE-Agile Hub-UK Demand",
    "IDE-PPM-PEX-Agile Delivery Lead",
    "IDE-DE-FE-BusinessAnalyst",
    "IDE-ERP-Technical",
    "IDE-DE-BA-DS-Mobility-CC Lead",
    "IDE-DE-BA-DS-Mobility-Fleet Solutions",
    "IDE-DE-BA-GF-Corporate",
    "IDE-PPM-Capital Projects & AD",
    "IDE-DE-BA-DS-Mobility-EUSA",
    "IDE-DE-TesterRetail-Settlement",
    "IDE-DE-RetailTestManagement",
    "IDE-PPM-PEX",
    "IDE-SE-NSE-Fleet Solutions",
    "IDE-BPPC-R&ES-Energy Marketing Engineering",
    "IDE-T&S-Shell Energy-Endur-EA",
    "IDE-T&S-Shell Energy-BA & E Design-SLMT",
    "IDE-ERP-S4-Technical",
    "IDE-DE-BA-PT-SEAT",
    "IDE-BPPC",
    "IDE-SSW",
    "IDE-Enterprise Resource Management",
    "IDE-T&S-Shell Energy-BA & E Design-Aligne",
    "IDE-IDA-DS-BF&DPF",
    "IDE-T&S-Shell Energy-BA & E Design-Asia & Aus",
    "IDE-DE-BA-DS-Mobility-Americas",
    "IDE-PPM-PMO",
    "IDE-IDA-Operational Excellence",
    "IDE-SE-NSE-S&D, Lubes & Cust Ops",
    "IDE-T&S-Powerhouse Geneva-Design",
    "IDE-T&S-Cross Comm-IDA",
    "IDE-PPM-Program & Project Mgmt.-IN-1",
    "IDE-SE-OsiSoft PI",
    "IDE-T&S-Cross Comm-BA",
    "IDE-DE-S&C",
    "IDE-DE-S&C-DS IDT-RES",
    "IDE-ERP-S4 Center of Expertise",
    "IDE-DE-PTIT-Others",
    "IDE-DE-BA-DS-Mobility-SIP",
    "IDE-T&S-Logistics-SE",
    "IDE-Design Engineering",
    "IDE-SE-Capital Projects",
    "IDE-T&S-Crude & Products-Testing\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0\u00c2\u00a0",
    "IDE-ERP Data",
    "IDE-ERP-Functional Excellence",
    "IDE-T&S-Powerhouse Geneva-Hub",
    "IDE-SE-ServiceNow, Workday, Automation",
    "IDE-PPM-RES IG",
    "IDE-T&S-Powerhouse Geneva",
    "IDE-DE-TesterRetail-BI&DA",
    "IDE-DE-CBDT_QA",
    "IDE-PPM-PEX-Agile Coach",
    "IDE-T&S-S&C",
    "IDE-PPM-GF",
    "IDE-PPM-DS-MOBILITY",
    "IDE-DE-Salesforce_QA",
    "IDE-DE-BA-DS-Mobility-GCC-Loyalty / Media & Campaign",
    "IDE-DE-BA-GF-Finance",
    "IDE-SE-Asset Mgmt & Realtime",
    "IDE-SE-Automation",
    "IDE-DE-IDSO_QA",
    "IDE-Trading & Supply",
    "IDE-SE-PEGA SSW",
    "IDE-ERP-S4 Delivery",
    "IDE-SE- Engineering and Maintenance",
    "IDE-DE-TesterRetail-NFR",
    "IDE-DE-Testing",
    "IDE-SE-Nearshoring Demand",
    "IDE-SE-Integration",
    "IDE-DE-BA-DS-Mobility-East",
    "IDE-IDA-Platform & Technology",
    "IDE-DE-BA-DS-SDLCP-Products",
    "IDE-DE-TesterRetail-SiteSystems",
    "IDE-T&S-Shell Energy- Endur-Asia",
    "IDE-DE-BA-DS-SDLCP",
    "IDE-T&S-Shell Energy-Testing",
    "IDE-DE-UPNE_QA",
    "IDE-IDA-DS-B2B, B2C & Shell Energy",
    "IDE-SE-NSE-Tech & Ops Excellence",
    "IDE-T&S-Crude & Products-IDA",
    "IDE-SE-NSE-R&ES, PTIT, Other LOB's",
    "IDE-T&S-Shell Energy- Endur",
    "IDE-T&S-Crude & Products-BA-Endur",
    "IDE-T&S-PPM-Logistics",
    "IDE-DE-S&C-IDSO-SOM",
    "IDE-SE-Realtime-IPSM",
    "IDE-T&S-Crude & Products-BA",
    "IDE-T&S-PPM-Crude & products",
    "IDE-SE-NSE-Cloud DevOps",
    "IDE-DE-S&C-DS IDT-NON-Mobility IT",
    "IDE-DE-BA-PT-SurfaceTechnology",
    "IDE-T&S-PPM-APM",
    "IDE-PPM-Program & Project Mgmt.-US",
    "IDE-SE-Asset Mgmt & Realtime-LIMS",
    "IDE-SE-Native SE & Experience Design",
    "IDE-DE-BA-DS-Mobility-Mobility Products",
    "IDE-DE-S&C-IRM IT",
    "IDE-PPM-PEX-Scrum Master",
    "IDE-T&S-Operational Excellence",
    "IDE-DE-BA-DS-Mobility-Data",
    "IDE-PPM-Program & Project Mgmt.-IN-2",
    "IDE-SE-Workday",
    "IDE-T&S-Cross Comm-SE",
    "IDE-DE-TesterRetail-NFT",
    "IDE-DE-GFIDT_QA",
    "IDE-PPM-Program & Project Mgmt.-NL",
    "IDE-PPM-PT UP",
    "IDE-DE-Nonfunctional_QA",
    "IDE-BPPC-R&ES",
    "IDE-DE-BA-GF-HR",
    "IDE-DE-BA-DS-Mobility-B2C",
    "IDE-Transformational Change",
    "IDE-T&S-Shell Energy-BA & E Design-EA Power",
    "IDE-DIY",
    "IDE-IDA-Chem & Products",
    "IDE-BPPC-R&ES-Energy Marketing Product",
    "IDE-DE-BA-DS-SDLCP-Lubes",
    "IDE-T&S-Shell Energy-Endur-US",
    "IDE-T&S-Shell Energy-BA & E Design-Horizontal",
    "IDE-IDA-P&T, IG & Upstream",
    "IDE-SE-NSE-Products & Pricing",
    "IDE-T&S-Shell Energy-BA & E Design-Americas",
    "IDE-DE-BA-DS-Mobility-Settlements",
    "IDE-SE-Business Platform",
    "IDE-SE-NSE-Mobility Others",
    "IDE-DE-BA-IPPD",
    "IDE-T&S-Shell Energy- Endur-Dev",
    "IDE-T&S-Shell Energy-BA & E Design-EPTB",
    "IDE-IDA-Data Mgt & Governance",
    "IDE-T&S-Logistics IDA",
    "IDE-DE-BA-DS-Mobility-Payments",
    "IDE-T&S-Shell Energy-BA & E Design-EA Gas",
    "IDE-SE-Technical Data",
    "IDE-T&S-Logistics-BA",
    "IDE-SE-Agile Hub-US Demand",
    "IDE-DE-BA-DS-Mobility-GCC-Digital Sales & Portfolio",
    "IDE-SE-NSE-Computational Software Engineering",
    "IDE-ERP",
    "IDE-DE-S&C-IDSO-ET&P",
    "IDE-SE-NSE-SSW",
    "IDE-DE-S&C-DS IDT-Mobility IT",
  ],
  "Ent IDT-Architecture & Data": [
    "Ent IDT-Architecture & Data-SEAT",
    "Ent IDT-Architecture & Data-DS T&S Shell Energy",
    "Ent IDT-Architecture & Data-UPIGPT Integrated Gas",
    "Ent IDT-Architecture & Data-DS Low Carbon Solutions",
    "Ent IDT-Architecture & Data-GF Corporate",
    "Ent IDT-Architecture & Data-DS Global Lubricants",
    "Ent IDT-Architecture & Data-GF Finance",
    "Ent IDT-Architecture & Data - Renewables",
    "Ent IDT-Architecture & Data-CIO Management",
    "Ent IDT-Architecture & Data-EA Functional Excellence",
    "Ent IDT-Architecture & Data-DS DnA & Customer Ops",
    "Ent IDT-Architecture & Data-EA Cross Business",
    "Ent IDT-Architecture & Data-EA Innovation",
    "Ent IDT-Architecture & Data-IDSO SWE & Workspace",
    "Ent IDT-Architecture & Data-DS Shared",
    "Ent IDT-Architecture & Data-EA Security & IRM",
    "Ent IDT-Architecture & Data-UPIGPT C&P-Functions",
    "Ent IDT-Architecture & Data-DS Shell Energy-EM",
    "Ent IDT-Architecture & Data-GF IT4IT",
    "Ent IDT-Architecture & Data-GF Legal",
    "Ent IDT-Architecture & Data-DS T&S-Horiz ShpngMrtm",
    "Ent IDT-Architecture & Data-DS B2B CRM & Ecommerce",
    "Ent IDT-Architecture & Data-IDSO Shared",
    "Ent IDT-Architecture & Data-UPIGPT COG-DeepWater",
    "Ent IDT-Architecture & Data-UPIGPT Projects & Engineering",
    "Ent IDT-Architecture & Data-DS T&S Back Office&Logistics",
    "Ent IDT-Architecture & Data-DS Mobility",
    "Ent IDT-Architecture & Data-DS T&S Crude&Products",
    "Ent IDT-Architecture & Data-UPIGPT Shared",
    "Ent IDT-Architecture & Data-IDSO Identity & Access Management",
    "Ent IDT-Architecture & Data-GF Shared & SOM",
    "Ent IDT-Architecture & Data-DS Global Lubes",
    "Ent IDT-Architecture & Data-IDSO Portfolio Integration",
    "Ent IDT-Architecture & Data-EA ERP SAP & DPF",
    "Ent IDT-Architecture & Data-UPIGPT SSW",
    "Ent IDT-Architecture & Data-IDSO Connectivity",
    "Ent IDT-Architecture & Data-IDSO Digital Platforms",
    "Ent IDT-Architecture & Data-S&P Enterprise Projects",
    "Ent IDT-Architecture & Data-IDSO End User Productivity",
    "Ent IDT-Architecture & Data-DS Chemicals & Products",
    "Ent IDT-Architecture & Data-IDSO ECP",
    "Ent IDT-Architecture & Data-IDSO Integration & Ops",
    "Ent IDT-Architecture & Data-GF HR",
  ],
  "IDSO-ET&P": [
    "IDSO-ET&P DS Delivery",
    "IDSO-ET&P-Others",
    "IDSO-ET&P GF Delivery",
    "IDSO-ET&P",
    "IDSO-ET&P Telecom Design",
    "IDSO-ET&P UPIGPT Delivery",
    "IDSO-ET&P Technical Consulting Non ERP",
    "IDSO-ET&P Enterprise Delivery",
    "IDSO-ET&P Technical Consulting ERP",
    "IDSO-ET&P Business Delivery",
  ],
};

export default groups;
