import React from "react";
import { useNavigate } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import DisplayMessage from "../../component/SkillsSearch/DisplayMessage/DisplayMessage";
import Search from "../../component/SkillsSearch/Search/Search";
import DisplaySkills from "../../component/SkillsSearch/DisplaySkills/DisplaySkills";
import Header from "../../component/SkillsSearch/Header/Header";
import CapabilityCenter from "../../component/SkillsSearch/CapabilityCenter/CapabilityCenter";
import HubComponent from "../../component/SkillsSearch/Hub/HubComponent";
import RoleComponent from "../../component/SkillsSearch/ResourceRoles/RoleComponent";
import "./SkillsSearch.css";
import useCustomSearchParams from "../../hooks/useCustomSearchParams";
import { SEARCH_PARAMS } from "../../const";

export default function SkillsSearch() {
  const navigate = useNavigate();
  const {
    validatedSkills,
    lob,
    hub,
    role,
    capCenter,
    listSkills,
    reset,
    fromDate,
    toDate,
  } = useCustomSearchParams();

  const handleNavigate = () => navigate("/");
  const handleGetResources = () => {
    navigate(
      `/Skills/Result?${SEARCH_PARAMS.CAPCENTER}=${encodeURIComponent(
        capCenter
      )}&${SEARCH_PARAMS.FROMDATE}=${fromDate}&${
        SEARCH_PARAMS.TODATE
      }=${toDate}&${SEARCH_PARAMS.VALIDATEDSKILLS}=${validatedSkills}&${
        SEARCH_PARAMS.ROLE
      }=${encodeURIComponent(role)}&${SEARCH_PARAMS.HUB}=${encodeURIComponent(
        hub
      )}&${SEARCH_PARAMS.LOB}=${encodeURIComponent(lob)}&${
        SEARCH_PARAMS.LISTSKILLS
      }=${encodeURIComponent(JSON.stringify(listSkills))}`
    );
  };
  const handleReset = () => reset();

  return (
    <div>
      <Header />
      <button className="back_btn" onClick={handleNavigate}>
        <ArrowBackIcon /> Home
      </button>

      <CapabilityCenter />

      <DisplayMessage
        message={`You have selected ${
          capCenter ? capCenter : "Any"
        } for Resource Group.`}
      />
      <HubComponent />

      <DisplayMessage
        message={`You have selected ${hub ? hub : "Any"} for Hub.`}
      />
      <RoleComponent />
      <DisplayMessage
        message={`You have selected ${role ? role : "Any"} for Resource Roles.`}
      />
      <p className="note">
        <span className="span-group-p">Note :</span>Resource Group , Resource
        Roles & Hub are optional. Skill and Proficiency is required.
      </p>
      <Search />

      {listSkills.length > 0 && (
        <>
          <DisplaySkills />
          <button className="ReviewButton" onClick={handleGetResources}>
            Get Resources
          </button>
          <button className="ReviewButton" onClick={handleReset}>
            Clear Filters
          </button>
        </>
      )}
    </div>
  );
}
