import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import skills from "../../../const/skills";
import { DEFAULT_SKILL_TYPE, INPUT_AUTO_COMPLETE_TYPE } from "../../../const";

import ValidateSkills from "../ValidatedSkills/ValidatedSkills";
import InputAutocomplete from "../InputAutocomplete/InputAutocomplete";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginTop: "1rem",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    padding: "0.40rem 0.6rem 0.40rem 0.6rem",
    fontSize: "0.65em",
    fontWeight: 500,
    color: "#000",
  },

  ccSearch: {
    display: "flex",
    justifyContent: "center",
    gap: "0.5rem",
    marginTop: "1rem",
  },
  searchDropdown: {
    fontWeight: 500,
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },

  ccSearchInput: {
    width: "20rem",
    fontSize: "0.95em",
    paddingLeft: "1em",
    fontWeight: 500,
    border: "1px solid rgb(222, 226, 230)",
    borderColor: theme.palette.divider, // Add this line to use the theme's divider color
  },
}));

export default function Search() {
  const [skillType, setSkillType] = useState(DEFAULT_SKILL_TYPE); //[skillType, setSkillType
  const [skill, setSkill] = useState(""); //[skill, setSkill
  const [proficiency, setProficiency] = useState("Any"); //[proficiency, setProficiency
  const skillTypes = Object.keys(skills);

  const options = skills[`${skillType}`]
    .flat()
    ?.sort((a, b) => a.localeCompare(b));

  const classes = useStyles();
  const { toDate, fromDate, setFromDate, setToDate, setListSkills } =
    useCustomSearchParams();
  const prevSkillRef = useRef();
  const prevProficiencyRef = useRef();

  useEffect(() => {
    console.log("skill", skill);
    console.log("proficiency", proficiency);
    if (
      (skill !== prevSkillRef.current ||
        proficiency !== prevProficiencyRef.current) &&
      proficiency !== "" &&
      skill !== ""
    ) {
      setListSkills({
        skill: skill,
        proficiency: proficiency,
      });
      prevSkillRef.current = skill;
      prevProficiencyRef.current = proficiency;
    }
  }, [skill, proficiency, setListSkills]);

  const handleProficiency = (e) => setProficiency(e.target.value);

  const handleSetFromDate = (e) => setFromDate(e.target.value);

  const handleSetToDate = (e) => setToDate(e.target.value);

  const handleSkillType = (e) => setSkillType(e.target.value);

  return (
    <div className="container">
      <p className="note">
        <span className="span-group-p">Note :</span>You can enter multiple
        skills.
      </p>
      <div className={classes.ccSearch}>
        <Typography className={classes.label}>Skill Type</Typography>
        <Select
          value={skillType}
          onChange={handleSkillType}
          sx={{ width: 200, height: "2.6em" }}
          placeholder="Skill Type"
        >
          {skillTypes.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>

        <Typography className={classes.label}>Skill</Typography>
        <InputAutocomplete
          setFunction={setSkill}
          type={INPUT_AUTO_COMPLETE_TYPE[0]}
          placeholderValue="Enter one skill at a time"
          options={options}
        />
        <Typography className={classes.label}>Proficiency</Typography>
        <select
          className={classes.searchDropdown}
          aria-label="Default select example"
          value={proficiency}
          placeholder="--Select Proficiency--"
          onChange={handleProficiency}
        >
          <option value="">--Select Proficiency--</option>
          <option value="Any">Any</option>
          <option value="Mastery">Mastery</option>
          <option value="Knowledge">Knowledge</option>
          <option value="Skill">Skill</option>
        </select>
        <ValidateSkills />
      </div>
      <p className="note" style={{ marginTop: "0.5rem" }}>
        <b>
          <span className="span-group-p">
            <b>
              Note : Start date and end date is optional. <br />
              By default, availability date will be displayed for next 3 months.
              Enter both start date and end date if you are looking for specific
              period.
            </b>
          </span>
        </b>
      </p>
      <div className={`${classes.ccSearch}`} style={{marginBottom:"1.5rem"}}>
        <Typography className={classes.label}>Start Date</Typography>
        <input
          required
          type="date"
          className={`${classes.ccSearchInput}`}
          value={fromDate}
          onChange={handleSetFromDate}
        />
        <Typography className={classes.label}>End Date</Typography>
        <input
          required
          type="date"
          className={`${classes.ccSearchInput}`}
          value={toDate}
          onChange={handleSetToDate}
        />
      </div>
    </div>
  );
}
