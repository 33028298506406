const roleList = [
  "Any",
  "AI Engineer",
  "Software Testing",
  "Supplier Delivery Manager",
  "Business Product Owner",
  "Product Line Manager",
  "Projects Business Analysis",
  "Analytics Engineer",
  "Data and Document Controller",
  "Product Manager",
  "Transformation Change-All",
  "Application Portfolio Manager",
  "Product Analyst",
  "IT Specialist",
  "Delivery Manager",
  "IT Strategy Consultant",
  "Data Lead",
  "Information Management Advisor",
  "IRM Compliance Management & Assurance",
  "IT Projects Business Analyst",
  "PMO",
  "Application/Service Support Analyst",
  "Data Architect",
  "Data Scientist",
  "Information & Data Manager",
  "Investment & Planning Manager",
  "IT Manager",
  "Landscape Manager",
  "Data Analyst",
  "Information Management Supervisor",
  "Demand Manager",
  "Information Management Manager",
  "IT Advisor",
  "Process Manager",
  "Competence Centre Manager",
  "Data Visualisation Engineer",
  "Information Management Business Analyst",
  "IRM Risk & Controls Management",
  "IT Strategy Manager",
  "Business Information Risk Management",
  "Data Engineer",
  "IT Resource Manager",
  "Portfolio Manager",
  "Scrum Master",
  "Service Assurance Manager",
  "Software Engineer",
  "Support Engineer",
  "Threat & Vulnerability Management",
  "Agile Coach",
  "Project Manager",
  "Segment Architect",
  "Situation Manager",
  "Solution Architect",
  "Technical Consultant",
  "Programme Manager",
  "Security Incident Management & Response",
  "Service Manager",
  "Software Quality Assurance Specialist",
  "Technical Architect",
  "Process Architect",
  "Security Architect",
  "Service Integration Manager",
  "Software Engineering Functional Excellence Specialist",
  "Systems Engineer",
  "IT Change Management",
];

export default roleList;
