import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, Select, Typography } from "@mui/material";

import groups from "./ResourceGroup";

import { INPUT_AUTO_COMPLETE_TYPE } from "../../../const";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

const useStyles = makeStyles((theme) => ({
  ccInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5em",
    marginTop: "1rem",
    marginBottom: "1rem",
    height: "2.3em",
  },
  label: {
    background: "#FCD839",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "2.3em",
    padding: "0.40rem 0.55rem 0.40rem 0.55rem",
    fontSize: "0.65em",
    fontWeight: 600,
    color: "#000",
  },
}));

export default function CapabilityCenter() {
  const classes = useStyles();
  const keys = Object.keys(groups);
  const { lob, setLob, setCapCenter, capCenter } = useCustomSearchParams();

  const options = groups[lob];

  const handleLobChange = (e) => setLob(e.target.value);

  const handleCapCenterChange = (e, value) => {
    if (value) setCapCenter(value);
    else setCapCenter("Any");
  };

  return (
    <>
      <div className={`${classes.ccInput}`}>
        <Typography className={classes.label}>Parent Resource Group</Typography>
        <Select
          value={lob}
          onChange={handleLobChange}
          sx={{ width: 200, height: "2.3em" }}
          placeholder="Line of Business"
        >
          {keys.map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.label}>Resource Group</Typography>
        <Autocomplete
          value={capCenter}
          options={options}
          onChange={handleCapCenterChange}
          type={INPUT_AUTO_COMPLETE_TYPE[1]}
          renderInput={(params) => (
            <TextField {...params} label="Resource Group" />
          )}
          sx={{
            width: 300,
          }}
        />
      </div>
    </>
  );
}
