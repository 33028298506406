import React from "react";
import { Grid, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowDownward, ArrowUpwardRounded } from "@mui/icons-material";

import LabelContent from "../LabelContent/LabelContent";

import DisplayTable from "../DisplayTable/DisplayTable";

import DisplayGridContainer from "../DisplayGridContainer/DisplayGridContainer";

import useCustomSearchParams from "../../../hooks/useCustomSearchParams";

import {
  NAME,
  SKILLS,
  RESOURCE_GROUP,
  EMPLOYEE_TYPE,
  START_DATE,
  END_DATE,
  AVERAGE_FTE,
  AVAILABLE_HOURS,
  RESOURCE_ROLE,
  HUB,
  DEVELOPMENT_SKILLS,
  VALIDATED_SKILLS,
  PREVIOUS_PROJECTS,
  AVAILABILITY,
  PROJECT_NUMBER,
  PROJECT_ROLE,
  PRIORITY,
  LINE_MANAGER,
  AVAILABILITY_MONTH_NAMES,
  AVAILABILITY_HOURS,
  PROJECT_NAME,
} from "../../../const/index";
import DisplayLineChart from "../DisplayLineChart/DisplayLineChart";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "85vh",
    overflowY: "auto",
    width: "80%",
    maxWidth: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonClick: {
    border: "none",
    background: "none",
  },
}));

const  DisplaySelectedResult = ({ result, setExpand, expand }) => {
  const classes = useStyles();
  const { fromDate, toDate } = useCustomSearchParams();

  let tableContents = result[PREVIOUS_PROJECTS].split(";");
  tableContents = tableContents.map((content) =>
    content.replace("(", "").replace(")", "").split(",")
  );
  tableContents = tableContents.map((content) => Object.values(content));

  let resourceDevelopmentSkills =
    result[DEVELOPMENT_SKILLS] === "[]" ? "None" : result[DEVELOPMENT_SKILLS];
  let resourceValidatedSkills =
    result[VALIDATED_SKILLS] === "[]" ? "None" : result[VALIDATED_SKILLS];

  const handleExpand = () => setExpand(!expand);

  return (
    <Modal open={expand} onClose={handleExpand}>
      <div className={classes.modal}>
        <Grid container maxHeight={"150em"}>
          <Grid item xs={11} md={11} lg={11}>
            <LabelContent labelHeading={result[NAME]} hr_width={100} />
          </Grid>
          <Grid xs={1} md={1} lg={1}>
            <button onClick={handleExpand} className={classes.buttonClick}>
              {expand ? <ArrowUpwardRounded /> : <ArrowDownward />}
            </button>
          </Grid>
          <DisplayGridContainer
            LabelHeadingList={[
              RESOURCE_ROLE,
              EMPLOYEE_TYPE,
              RESOURCE_GROUP,
              HUB,
            ]}
            LabelValueList={[
              result[RESOURCE_ROLE],
              result[EMPLOYEE_TYPE],
              result[RESOURCE_GROUP],
              result[HUB],
            ]}
            columnSize={3}
            hr_width={40}
          />
        </Grid>
        <Grid container style={{ marginTop: "2rem" }}>
          <DisplayGridContainer
            LabelHeadingList={[
              START_DATE,
              END_DATE,
              AVAILABILITY,
              LINE_MANAGER,
            ]}
            LabelValueList={[
              fromDate,
              toDate,
              `${result[AVERAGE_FTE]} (or) ${result[AVAILABLE_HOURS]} hours`,
              `${result[LINE_MANAGER]}`,
            ]}
            columnSize={3}
            hr_width={60}
          />
        </Grid>
        <Grid container style={{ marginTop: "3rem" }}>
          <DisplayGridContainer
            LabelHeadingList={[SKILLS, DEVELOPMENT_SKILLS, VALIDATED_SKILLS]}
            LabelValueList={[
              result[SKILLS],
              resourceDevelopmentSkills,
              resourceValidatedSkills,
            ]}
            hr_width={60}
          />
        </Grid>
        <Grid container style={{ marginBottom: "2rem" }}>
          <Grid xs={12} md={12} lg={12}>
            <LabelContent
              labelHeading={"Availability Graph"}
              hr_width={100}
              labelValue=" "
            >
              <DisplayLineChart hoursData={result[AVAILABILITY_HOURS]}
               monthLabels={result[AVAILABILITY_MONTH_NAMES]}/>
            </LabelContent>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: "2rem" }}>
          <Grid xs={12} md={12} lg={12}>
            <LabelContent
              labelHeading={"Previous Projects and Experiences"}
              hr_width={100}
              labelValue=" "
            >
              <DisplayTable
                columnNames={[
                  PROJECT_NUMBER,
                  PROJECT_ROLE,
                  PROJECT_NAME,
                  PRIORITY,
                ]}
                cellValues={tableContents}
                tableFor=" "
              />
            </LabelContent>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
export default DisplaySelectedResult;
