import React, { useState } from "react";
import { Badge, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArrowDownward, ArrowUpwardRounded } from "@mui/icons-material";

import LabelContent from "../LabelContent/LabelContent";
import DisplaySelectedResult from "../DisplaySelectedResult/DisplaySelectedResult";

import {
  HUB,
  MATCHING_PERCENTAGE,
  NAME,
  RESOURCE_GROUP,
  RESOURCE_ROLE,
} from "../../../const";

const useStyles = makeStyles((theme) => ({
  displayResultCard: {
    border: "1px solid #EB8705",
    padding: "0.5rem",
    marginTop: "2em",
    borderRadius: "0.5em",
  },
  buttonClick: {
    border: "none",
    background: "none",
  },
}));

const DisplayResultCard = ({ result }) => {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  return (
    <Container md={12} maxWidth={"60rem"} xs={12}>
      {expand === false && (
        <Badge
          color="primary"
          badgeContent={`Number of Matching Skills: ${result[MATCHING_PERCENTAGE]}`}
          overlap="rectangle"
          mt={1}
        >
          <Grid container md={12} className={classes.displayResultCard} mb={1}>
            <Grid xs={3} md={3} lg={3}>
              <LabelContent
                labelHeading={NAME}
                labelValue={result[NAME]}
                hr_width={60}
              />
            </Grid>
            <Grid xs={3} md={3} lg={3}>
              <LabelContent
                labelHeading={RESOURCE_ROLE}
                labelValue={result[RESOURCE_ROLE]}
                hr_width={60}
              />
            </Grid>
            <Grid xs={3} md={3} lg={3}>
              <LabelContent
                labelHeading={RESOURCE_GROUP}
                labelValue={result[RESOURCE_GROUP]}
                hr_width={60}
              />
            </Grid>
            <Grid xs={2} md={2} lg={2}>
              <LabelContent
                labelHeading="Hub"
                labelValue={result[HUB]}
                hr_width={40}
              />
            </Grid>
            <Grid xs={1} md={1} lg={1}>
              <button
                className={classes.buttonClick}
                onClick={() => setExpand(!expand)}
              >
                {expand ? <ArrowUpwardRounded /> : <ArrowDownward />}
              </button>
            </Grid>
          </Grid>
        </Badge>
      )}
      {expand === true && (
        <DisplaySelectedResult
          result={result}
          setExpand={setExpand}
          expand={expand}
        />
      )}
    </Container>
  );
};

export default DisplayResultCard;
